import aesher_logo from "../images/logo/aesher_logo.png";
import besa_logo from "../images/logo/besa_logo.png";
import cynosmetics_logo from "../images/logo/cynosmetics_logo.png";
import fadaro_logo from "../images/logo/fadaro_logo.png";
import lws_logo from "../images/logo/lws-logo.png";

const dataPartner = [
  {
    id: 1,
    img: aesher_logo,
  },
  {
    id: 2,
    img: besa_logo,
  },
  {
    id: 3,
    img: cynosmetics_logo,
  },
  {
    id: 4,
    img: fadaro_logo,
  },
  {
    id: 5,
    img: lws_logo,
  },
  {
    id: 1,
    img: aesher_logo,
  },
  {
    id: 2,
    img: besa_logo,
  },
  {
    id: 3,
    img: cynosmetics_logo,
  },
  {
    id: 4,
    img: fadaro_logo,
  },
  {
    id: 5,
    img: lws_logo,
  },
];

export default dataPartner;
