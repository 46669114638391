import React from "react";
import not_found from "../assets/images/item/404.png";

function Page404(props) {
  return (
    <div style={styles.container}>
      <img src={not_found} alt="Ortalama Resim" style={styles.image} />
    </div>
  );
}

const styles = {
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh', // Tam ekran yüksekliği için
    },
    image: {
      maxWidth: '100%',
      maxHeight: '100%',
      objectFit: 'contain', // Resmin şeklini bozmadan sığdırır
    }
  };
export default Page404;
