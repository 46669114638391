import fadaro_image from "../images/item/fadaro-thumbnail.jpg";

import gleart_image from "../images/item/gleart.jpg";
import good_stuff_image from "../images/item/good_stuff.jpg";
import kav_image from "../images/item/kav-thumbnail.jpg";

import midnight_image from "../images/item/midnight-thumbnail.jpg";
import milky_image from "../images/item/milky.jpg";
import poster_futbol_image from "../images/item/messi.jpg";
import poster_image from "../images/item/afisler.jpg";
import ulgar_image from "../images/item/ulgar.jpg";

import sosyal_medya from "../images/item/sosyal-medya.jpg";

import berry_image from "../images/item/berry.jpg";
import naturepure_image from "../images/item/naturepure.jpg";
import flake_image from "../images/item/flake.jpg";
import aesher_image from "../images/item/aesher.jpg";
import besa_image from "../images/item/besa.jpg";

import cynosmetics2 from "../images/item/cynocosmetic.jpg";

const dataItem = [
  {
    id: 1,
    img: flake_image,
    title: "Flake",
    url: "flake",
  },
  {
    id: 1,
    img: naturepure_image,
    title: "Naturepure",
    url: "naturepure",
  },
  {
    id: 1,
    img: aesher_image,
    title: "Aesher",
    url: "aesher",
  },
  {
    id: 1,
    img: besa_image,
    title: "Besa",
    url: "besa",
  },
  {
    id: 1,
    img: fadaro_image,
    title: "Fadaro",
    url: "fadaro",
  },
  {
    id: 1,
    img: cynosmetics2,
    title: "cynosmetics",
    url: "cynosmetics",
  },
  {
    id: 5,
    img: milky_image,
    title: "Milky",
    url: "milky",
  },
  {
    id: 9,
    img: kav_image,
    title: "Kav",
    url: "kav",
  },
  {
    id: 3,
    img: gleart_image,
    title: "Gleart",
    url: "gleart",
  },
  {
    id: 3,
    img: sosyal_medya,
    title: "Sosyal Medya",
    url: "social-media",
  },
  {
    id: 1,
    img: berry_image,
    title: "Berry",
    url: "berry",
  },
  {
    id: 2,
    img: midnight_image,
    title: "Midnight",
    url: "midnight",
  },
  {
    id: 10,
    img: good_stuff_image,
    title: "Good Stuff Jewelry",
    url: "good-stuff-jewelry",
  },
  {
    id: 11,
    img: poster_futbol_image,
    title: "Poster Futbol",
    url: "football-poster",
  },
  {
    id: 11,
    img: poster_image,
    title: "Poster",
    url: "poster",
  },
  {
    id: 11,
    img: ulgar_image,
    title: "Ulgar",
    url: "ulgar",
  },
];

export default dataItem;
