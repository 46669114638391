import React from "react";
import { Link } from "react-router-dom";
import behance from "../../assets/images/icon/behance.png";
import instagram from "../../assets/images/icon/instagram.png";
import linkedin from "../../assets/images/icon/linkedin.png";

function Welcome(props) {
  return (
    <section className="banner">
      <div className="shape right"></div>
      <div className="container big">
        <div className="row">
          <div className="col-xl-6 col-md-12">
            <div className="banner__left">
              <div className="block-text">
                <h2 className="heading">
                  Fikirlerinizi bizimle <br />
                  güçlü bir{" "}
                  <span className="arlo_tm_animation_text_word">
                    marka
                  </span>{" "}
                  <br />
                  haline getirin!
                </h2>
                <p className="desc">
                  Grafik tasarımda sanatı ve stratejiyi birleştiriyoruz.
                  Fikirlerinizi markalaştırırken her detayda kalite ve özgünlük
                  sunuyoruz.
                </p>

                <Link to="/contact" className="action-btn">
                  <span className="fero">İLETİŞİME GEÇ</span>
                </Link>
              </div>

              <div className="pay">
                <h6>Bağlantılar :</h6>

                <div className="list">
                  <ul>
                    <li>
                      <a
                        href="https://www.instagram.com/rokokocreative/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          style={{ width: 32, height: 32 }}
                          src={instagram}
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.behance.net/RokokoCreativeAgency"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img style={{ width: 32, height: 32 }} src={behance} />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/rokoko-agency/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img style={{ width: 32, height: 32 }} src={linkedin} />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-md-12">
            <div className="banner__right">
              <div className="block-text">
                <div className="image">&nbsp;</div>
                <div style={{ backgroundColor: "transparent" }} className="owner">
                  <div
                    style={{
                      width: 550,
                      height: 80,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    className="content test2"
                  >
                    <h5 className="test">“ Transforming ideas <br/>into icons ”</h5>
                    <h5 className="test"></h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Welcome;
