import flake_image from "../images/item/flake.jpg";
import naturepure_image from "../images/item/naturepure.jpg";
import aesher_image from "../images/item/aesher.jpg";
import besa_image from "../images/item/besa.jpg";

import cynosmetics2 from "../images/item/cynocosmetic.jpg";

const homePortfolyo = [
  {
    id: 1,
    img: flake_image,
    title: "Flake / Breakfast & Bakery",
    url: "flake"
  },
  {
    id: 2,
    img: naturepure_image,
    title: "NaturaPure / Organic & Natural Soap",
    url: "naturepure"
  },
  {
    id: 3,
    img: aesher_image,
    title: "Aesher",
    url: "aesher"
  },
  {
    id: 4,
    img: besa_image,
    title: "Besa / Jewerly & Design & Manufacturer",
    url: "besa"
  },
  {
    id: 8,
    img: cynosmetics2,
    title: "Cynosmetic / Sunscreen",
    url: "cynosmetics"
  }
];

export default homePortfolyo;
