import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

Project3.propTypes = {
  data: PropTypes.array,
};

function Project3(props) {
  const { data } = props;
  return (
    <section className="nft">
      <div className="container">
        <div className="row">
          {data.map((idx) => (
            <div key={idx.id} className="col-xl-3 col-md-6">
              <div className="nft-item">
                <div className="card-media">
                  <Link to={idx.url}>
                    <img src={idx.img} />
                  </Link>
                </div>
                <div className="card-title">
                  <Link
                    style={{ textAlign: "center", display: "block" }}
                    to={idx.url}
                    className="h5"
                  >
                    {idx.title}
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Project3;
