import React from "react";
import About2 from "../components/about/About2";
import Footer from "../components/footer/Footer";

function About(props) {
  return (
    <div className="page-about wrapper">
      <About2 />
      <Footer />
    </div>
  );
}

export default About;
