import React from "react";
import Footer from "../components/footer/Footer";
import flake_image from "../assets/images/item/flake3.jpg";
import poster_image from "../assets/images/item/poster.jpg";
import besa_image from "../assets/images/item/besa.jpg";

function Services(props) {
  return (
    <div className="page-visions wrapper">
      <section className="about">
        <div className="container">
          <div style={{ marginBottom: 100 }} className="row">
            <div className="col-xl-6 col-md-12">
              <div className="block-text">
                <h4 className="heading wow" data-splitting>
                  Kurumsal Kimlik Tasarımı
                </h4>
                <p>
                  Markanızın benzersiz kimliğini oluşturuyoruz. Logo, kartvizit
                  ve diğer kurumsal materyallerle, markanızın tutarlı ve güçlü
                  bir imajını yansıtıyoruz.
                </p>
              </div>
              <div className="block-text">
                <h4 className="heading wow" data-splitting>
                  Sosyal Medya Yönetimi ve Post Tasarımı
                </h4>
                <p className="mb-17">
                  Sosyal medya stratejinizi güçlendirmek için etkileyici ve
                  özgün içerikler oluşturuyor, sosyal medya hesaplarınızın
                  yönetimini profesyonel bir şekilde gerçekleştiriyoruz.
                </p>
              </div>
            </div>
            <div className="col-xl-6 col-md-12">
              <div className="about__right">
                <div className="images">
                  <img className="img1" src={flake_image} />
                </div>
              </div>
            </div>
          </div>
          <div style={{ marginBottom: 100 }} className="row">
            <div className="col-xl-6 col-md-12">
              <div className="block-text">
                <h4 className="heading wow" data-splitting>
                  Afiş ve Poster Tasarımı
                </h4>
                <p>
                  Etkinlikleriniz ve kampanyalarınız için dikkat çekici afiş ve poster tasarımları hazırlıyoruz. Görsel olarak güçlü, etkili ve özgün tasarımlar sunuyoruz.
                </p>
              </div>
              <div className="block-text">
                <h4 className="heading wow" data-splitting>
                  Video Kurgu ve Hareketli Video Animasyonları
                </h4>
                <p className="mb-17">
                  Profesyonel video kurguları ve dinamik animasyon tasarımlarıyla, mesajınızı etkileyici bir şekilde iletmenizi sağlıyoruz. Videolarınızın tüm aşamalarında yaratıcı çözümler sunuyoruz.
                </p>
              </div>
            </div>
            <div className="col-xl-6 col-md-12">
              <div className="about__right">
                <div className="images">
                  <img className="img1" src={poster_image} />
                </div>
              </div>
            </div>
          </div>
          
          <div className="row">
            <div className="col-xl-6 col-md-12">
              <div className="block-text">
                <h4 className="heading wow" data-splitting>
                  Ambalaj Tasarımı
                </h4>
                <p className="mb-17">
                  Ürünlerinizin ambalajlarını estetik ve fonksiyonel bir şekilde
                  tasarlayarak, markanızın değerlerini ve ürünlerinizi en iyi
                  şekilde temsil ediyoruz.
                </p>
              </div>
              <div className="block-text">
                <h4 className="heading wow" data-splitting>
                  Web Tasarımı
                </h4>
                <p className="mb-17">
                  Web siteniz, markanızın dijital dünyadaki yüzüdür. Rokoko
                  Creative Agency olarak, markanızı en iyi şekilde temsil edecek
                  kullanıcı dostu, estetik ve modern web siteleri tasarlıyoruz.
                  Mobil uyumlu ve SEO dostu çözümlerimizle, dijital dünyada
                  güçlü bir varlık oluşturmanıza yardımcı oluyoruz.
                </p>
              </div>
            </div>
            <div className="col-xl-6 col-md-12">
              <div className="about__right">
                <div className="images">
                  <img className="img1" src={besa_image} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default Services;
