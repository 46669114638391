import React from "react";
import Welcome from "../components/home/Welcome";
import Portfolio from "../components/home/Portfolio";
import homePortfolyo from "../assets/data/home-portfolyo";
import Services from "../components/home/Services";
import homeServices from "../assets/data/home-services";
import Workflow from "../components/home/Workflow";
import homeWorkflow from "../assets/data/home-workflow";
import Partner from "../components/home/Partner";
import Footer from "../components/footer/Footer";

function Home() {
  return (
    <div className="home-1 wrapper">
      <Welcome />
      <Portfolio data={homePortfolyo} />
      <Services data={homeServices} />
      <Workflow data={homeWorkflow} />
      <Partner />
      <Footer />
    </div>
  );
}

export default Home;
