import besa1 from "../images/item/besa-1.jpg";
import besa2 from "../images/item/besa-2.jpg";
import besa3 from "../images/item/besa-3.jpg";

import cynosmetics1 from "../images/item/cynosmetics-1.jpg";
import cynosmetics2 from "../images/item/cynosmetics-2.jpg";
import cynosmetics3 from "../images/item/cynosmetics-3.jpg";
import cynosmetics4 from "../images/item/cynosmetics-4.jpg";

import aesher1 from "../images/item/resher1.jpg";
import aesher2 from "../images/item/resher2.jpg";
import aesher3 from "../images/item/resher3.jpg";
import aesher4 from "../images/item/resher4.jpg";
import aesher5 from "../images/item/resher5.jpg";

import milky3 from "../images/item/milky-3.jpg";
import milky4 from "../images/item/milky-4.jpg";
import milky5 from "../images/item/milky-5.jpg";

import football_poster1 from "../images/item/futbol-afis-1.jpg";
import football_poster2 from "../images/item/futbol-afis-2.jpg";
import football_poster3 from "../images/item/futbol-afis-3.jpg";
import football_poster4 from "../images/item/futbol-afis-4.jpg";
import football_poster5 from "../images/item/futbol-afis-5.jpg";

import gleart1 from "../images/item/gleart-1.jpg";
import gleart2 from "../images/item/gleart-2.jpg";
import gleart3 from "../images/item/gleart-3.jpg";
import gleart4 from "../images/item/gleart-4.jpg";
import gleart5 from "../images/item/gleart-5.jpg";
import gleart6 from "../images/item/gleart-6.jpg";
import gleart7 from "../images/item/gleart-7.jpg";
import gleart8 from "../images/item/gleart-8.jpg";
import gleart9 from "../images/item/gleart-9.jpg";

import ulgar1 from "../images/item/ulgar1.jpg";
import ulgar2 from "../images/item/ulgar2.jpg";
import ulgar3 from "../images/item/ulgar3.jpg";

import goodstuff1 from "../images/item/goodstuff-1.jpg";
import goodstuff2 from "../images/item/goodstuff-2.jpg";
import goodstuff3 from "../images/item/goodstuff-3.jpg";

import mr1 from "../images/item/mr-1.jpg";
import mr2 from "../images/item/mr-2.jpg";
import mr3 from "../images/item/mr-3.jpg";
import mr4 from "../images/item/mr-4.jpg";

import socialmedia1 from "../images/item/social-media-1.jpg";

import fadaro1 from "../images/item/fadaro1.jpg";
import fadaro2 from "../images/item/fadaro2.jpg";
import fadaro3 from "../images/item/fadaro3.jpg";
import fadaro4 from "../images/item/fadaro4.jpg";

import natura1 from "../images/item/natura-1.jpg";
import natura2 from "../images/item/natura-2.jpg";
import natura3 from "../images/item/natura-3.jpg";
import natura4 from "../images/item/natura-4.jpg";
import natura5 from "../images/item/natura-5.jpg";
import natura6 from "../images/item/natura-6.jpg";
import natura7 from "../images/item/natura-7.jpg";

import flake1 from "../images/item/flake1.jpg";
import flake2 from "../images/item/flake2.jpg";
import flake3 from "../images/item/flake3.jpg";
import flake4 from "../images/item/flake4.jpg";
import flake5 from "../images/item/flake5.jpg";
import flake6 from "../images/item/flake6.jpg";
import flake7 from "../images/item/flake7.jpg";

import berry2 from "../images/item/berry2.jpg";

import kav from "../images/item/kav-thumbnail.jpg";

import poster1 from "../images/item/poster1.jpg";
import poster2 from "../images/item/poster2.jpg";
import poster3 from "../images/item/poster3.jpg";
import poster4 from "../images/item/poster4.jpg";

const dataPortfolio = [
  {
    id: 1,
    slug: "besa",
    title: "Besa / Jewerly & Design & Manufacturer",
    images: [
      {
        title: "Besa image",
        image: besa1,
      },
      {
        title: "Besa image",
        image: besa2,
      },
      {
        title: "Besa image",
        image: besa3,
      },
    ],
  },
  {
    id: 2,
    slug: "cynosmetics",
    title: "Cynosmetic / Sunscreen",
    images: [
      {
        title: "Cynosmetics image",
        image: cynosmetics1,
      },
      {
        title: "Cynosmetics image",
        image: cynosmetics2,
      },
      {
        title: "Cynosmetics image",
        image: cynosmetics3,
      },
      {
        title: "Cynosmetics image",
        image: cynosmetics4,
      },
    ],
  },
  {
    id: 3,
    slug: "aesher",
    title: "Aesher",
    images: [
      {
        title: "Aesher image",
        image: aesher1,
      },
      {
        title: "Aesher image",
        image: aesher2,
      },
      {
        title: "Aesher image",
        image: aesher3,
      },
      {
        title: "Aesher image",
        image: aesher4,
      },
      {
        title: "Aesher image",
        image: aesher5,
      },
    ],
  },
  {
    id: 4,
    slug: "milky",
    title: "Milky / Ice Cream",
    images: [
      {
        title: "Milky image",
        image: milky3,
      },
      {
        title: "Milky image",
        image: milky4,
      },
      {
        title: "Milky image",
        image: milky5,
      },
    ],
  },
  {
    id: 5,
    slug: "football-poster",
    title: "Football Poster",
    images: [
      {
        title: "Football Poster",
        image: football_poster1,
      },
      {
        title: "Football Poster",
        image: football_poster2,
      },
      {
        title: "Football Poster",
        image: football_poster3,
      },
      {
        title: "Football Poster",
        image: football_poster4,
      },
      {
        title: "Football Poster",
        image: football_poster5,
      },
    ],
  },
  {
    id: 6,
    slug: "gleart",
    title: "GleArt / Makeup",
    images: [
      {
        title: "Gleart Image",
        image: gleart1,
      },
      {
        title: "Gleart Image",
        image: gleart2,
      },
      {
        title: "Gleart Image",
        image: gleart3,
      },
      {
        title: "Gleart Image",
        image: gleart4,
      },
      {
        title: "Gleart Image",
        image: gleart5,
      },
      {
        title: "Gleart Image",
        image: gleart6,
      },
      {
        title: "Gleart Image",
        image: gleart7,
      },
      {
        title: "Gleart Image",
        image: gleart8,
      },
      {
        title: "Gleart Image",
        image: gleart9,
      },
    ],
  },
  {
    id: 7,
    slug: "ulgar",
    title: "Ulgar",
    images: [
      {
        title: "Ulgar image",
        image: ulgar1,
      },
      {
        title: "Ulgar image",
        image: ulgar2,
      },
      {
        title: "Ulgar image",
        image: ulgar3,
      },
    ],
  },
  {
    id: 8,
    slug: "good-stuff-jewelry",
    title: "Good Stuff Jewelry",
    images: [
      {
        title: "Good Stuff Jewelry Image",
        image: goodstuff1,
      },
      {
        title: "Good Stuff Jewelry Image",
        image: goodstuff2,
      },
      {
        title: "Good Stuff Jewelry Image",
        image: goodstuff3,
      },
    ],
  },
  {
    id: 9,
    slug: "midnight",
    title: "Midnight Rain / Eau De Parfum",
    images: [
      {
        title: "Midnight Image",
        image: mr1,
      },
      {
        title: "Midnight Image",
        image: mr2,
      },
      {
        title: "Midnight Image",
        image: mr3,
      },
      {
        title: "Midnight Image",
        image: mr4,
      },
    ],
  },
  {
    id: 10,
    slug: "social-media",
    title: "Social Media",
    images: [
      {
        title: "Social Media",
        image: socialmedia1,
      },
    ],
  },
  {
    id: 11,
    slug: "fadaro",
    title: "Fadaro / Fine Jewerly",
    images: [
      {
        title: "Fadaro Image",
        image: fadaro1,
      },
      {
        title: "Fadaro Image",
        image: fadaro2,
      },
      {
        title: "Fadaro Image",
        image: fadaro3,
      },
      {
        title: "Fadaro Image",
        image: fadaro4,
      },
    ],
  },
  {
    id: 1,
    slug: "naturepure",
    title: "NaturaPure / Organic & Natural Soap",
    images: [
      {
        title: "Natura image",
        image: natura1,
      },
      {
        title: "Natura image",
        image: natura2,
      },
      {
        title: "Natura image",
        image: natura3,
      },
      {
        title: "Natura image",
        image: natura4,
      },
      {
        title: "Natura image",
        image: natura5,
      },
      {
        title: "Natura image",
        image: natura6,
      },
      {
        title: "Natura image",
        image: natura7,
      },
    ],
  },
  {
    id: 1,
    slug: "flake",
    title: "Flake / Breakfast & Bakery",
    images: [
      {
        title: "Flake image",
        image: flake1,
      },
      {
        title: "Flake image",
        image: flake7,
      },
      {
        title: "Flake image",
        image: flake2,
      },
      {
        title: "Flake image",
        image: flake3,
      },
      {
        title: "Flake image",
        image: flake4,
      },
      {
        title: "Flake image",
        image: flake5,
      },
      {
        title: "Flake image",
        image: flake6,
      },
    ],
  },
  {
    id: 1,
    slug: "berry",
    title: "Berry",
    images: [
      {
        title: "Berry image",
        image: berry2,
      },
    ],
  },
  {
    id: 1,
    slug: "kav",
    title: "Kav",
    images: [
      {
        title: "Kav image",
        image: kav,
      },
    ],
  },
  {
    id: 1,
    slug: "poster",
    title: "Poster",
    images: [
      {
        title: "Poster image",
        image: poster1,
      },
      {
        title: "Poster image",
        image: poster2,
      },
      {
        title: "Poster image",
        image: poster3,
      },
      {
        title: "Poster image",
        image: poster4,
      },
    ],
  },
];

export default dataPortfolio;
