import React, { useState } from "react";

function About2(props) {
  const [dataBlock] = useState({
    heading: "Hakkımızda",
    desc1:
      "Rokoko Creative Agency olarak, 6 kişilik yetenekli ve tutkulu bir ekibiz ve markanızı benzersiz kılacak yaratıcı çözümler sunmak için bir araya geldik. Her birimiz kendi alanımızda uzmanız: grafik tasarım, video kurgu ve edit, reklam içerikleri, web sitesi kodlama, fotoğraf çekimi ve kurumsal kimlik oluşturma. Amacımız, yalnızca hizmet sunmak değil, markanızı rakiplerinizin önüne taşıyacak etkili ve akılda kalıcı çözümler üretmektir.",
    desc2:
      "Tasarım yapmayı bir tutku olarak görüyor ve her projeye aynı heyecanla yaklaşıyoruz. Markanızın ihtiyaçlarına odaklanarak, görsel dili güçlü, dikkat çekici ve stratejik çözümler sunuyoruz. Her projede, markanızı bir adım ileriye taşıyacak ve hedef kitlenizi etkileyecek işler ortaya koymayı hedefliyoruz. Başarınız, bizim de başarımızdır.",
    desc3:
      "Sizinle çalışarak, markanızı yalnızca bir adım öne çıkarmakla kalmıyor, ona hikaye ve kimlik kazandırıyoruz. Her projeye verdiğimiz özen ve yaratıcılıkla, sizin için en iyisini tasarlamaya hazırız. Rokoko Creative Agency ile tanışın ve markanızın sınırlarını yeniden keşfedin.",
  });
  return (
    <section className="about s2">
      <div className="shape"></div>
      <div className="shape right"></div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="block-text center pd-0">
              <h3 className="heading pd">{dataBlock.heading}</h3>
            </div>

            <div
              className="about__main center"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <p style={{ fontSize: 20 }} className="mb-17">
                {dataBlock.desc1}
              </p>
              <p style={{ fontSize: 20 }} className="mb-30">
                {dataBlock.desc2}
              </p>
              <p style={{ fontSize: 20 }} className="mb-30">
                {dataBlock.desc3}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About2;
