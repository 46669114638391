const menus = [
  {
    id: 1,
    name: "Anasayfa",
    links: "/",
  },
  {
    id: 2,
    name: "Hakkımızda",
    links: "/about",
  },
  {
    id: 3,
    name: "Hizmetlerimiz",
    links: "/services",
  },
  {
    id: 5,
    name: "Portfolyo",
    links: "/portfolio",
  },
  {
    id: 6,
    name: "İletişim",
    links: "/contact",
  },
  {
    id: 7,
    name: "Converter",
    links: "/converter",
  }
];

export default menus;
