import React from "react";

import Footer from "../components/footer/Footer";

import Project3 from "../components/project/Project3";
import dataItem from "../assets/fake-data/data-item";

function Portfolio(props) {
  return (
    <div className="wrapper">
      <Project3 data={dataItem} />
      <Footer />
    </div>
  );
}

export default Portfolio;
