import React from "react";
import behance from "../assets/images/icon/behance.png";
import instagram from "../assets/images/icon/instagram.png";
import linkedin from "../assets/images/icon/linkedin.png";

import Footer from "../components/footer/Footer";

function Contact() {
  return (
    <div className="wrapper">
      <section className="touch">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="block-text center">
                <h3 className="heading">
                  Let’s Start Working <br />
                  Together
                </h3>
              </div>
              <div className="touch__main">
                <div className="col-12">
                  <div className="info">
                    <h5>İletişim bilgilerimiz</h5>
                    <ul className="list">
                      <li>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10 7C10 7.53043 9.78929 8.03914 9.41421 8.41421C9.03914 8.78929 8.53043 9 8 9C7.46957 9 6.96086 8.78929 6.58579 8.41421C6.21071 8.03914 6 7.53043 6 7C6 6.46957 6.21071 5.96086 6.58579 5.58579C6.96086 5.21071 7.46957 5 8 5C8.53043 5 9.03914 5.21071 9.41421 5.58579C9.78929 5.96086 10 6.46957 10 7V7Z"
                            stroke="white"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M13 7C13 11.7613 8 14.5 8 14.5C8 14.5 3 11.7613 3 7C3 5.67392 3.52678 4.40215 4.46447 3.46447C5.40215 2.52678 6.67392 2 8 2C9.32608 2 10.5979 2.52678 11.5355 3.46447C12.4732 4.40215 13 5.67392 13 7V7Z"
                            stroke="white"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <p>
                          İstanbul Levent, Kolektif House, Rokoko Creative
                          Agency
                        </p>
                      </li>
                      <li>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.5 4.5V11.5C14.5 11.8978 14.342 12.2794 14.0607 12.5607C13.7794 12.842 13.3978 13 13 13H3C2.60218 13 2.22064 12.842 1.93934 12.5607C1.65804 12.2794 1.5 11.8978 1.5 11.5V4.5M14.5 4.5C14.5 4.10218 14.342 3.72064 14.0607 3.43934C13.7794 3.15804 13.3978 3 13 3H3C2.60218 3 2.22064 3.15804 1.93934 3.43934C1.65804 3.72064 1.5 4.10218 1.5 4.5M14.5 4.5V4.662C14.5 4.9181 14.4345 5.16994 14.3096 5.39353C14.1848 5.61712 14.0047 5.80502 13.7867 5.93933L8.78667 9.016C8.55014 9.16169 8.2778 9.23883 8 9.23883C7.7222 9.23883 7.44986 9.16169 7.21333 9.016L2.21333 5.94C1.99528 5.80569 1.81525 5.61779 1.69038 5.3942C1.56551 5.1706 1.49997 4.91876 1.5 4.66267V4.5"
                            stroke="white"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <p>info@rokokocreative.com</p>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/rokokocreative/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            style={{ width: 32, height: 32 }}
                            src={instagram}
                          />
                        </a>
                        <a
                          href="https://www.behance.net/RokokoCreativeAgency"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            style={{ width: 32, height: 32 }}
                            src={behance}
                          />
                        </a>
                        <a
                          href="https://www.linkedin.com/company/rokoko-agency/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            style={{ width: 32, height: 32 }}
                            src={linkedin}
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default Contact;
