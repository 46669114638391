import { React, useState, useEffect } from "react";
import Footer from "../components/footer/Footer";
import dataPortfolio from "../assets/fake-data/data-portfolio";
import { useParams } from "react-router-dom";

function PortfolioDetails(props) {
  const { slug } = useParams();
  const [portfolio, setPortfolio] = useState(null);

  useEffect(() => {
    const foundPortfolio = dataPortfolio.find(
      (portfolio) => portfolio.slug === slug
    );
    setPortfolio(foundPortfolio);
  }, [slug]);
  if (!portfolio) {
    return <p>Loading...</p>;
  }
  return (
    <div className="wrapper">
      <section className="bloglist">
        <div className="container">
          <div className="row">
            <div className="bloglist__main">
              <div className="list details">
                <h4 className="title">{portfolio.title}</h4>

                <div className="line"></div>

                <div className="main">
                    {portfolio.images.map((idx) => (
                      <img
                      className="img1"
                      src={idx.image}
                      alt={idx.title}
                      style={{ display: "block", margin: "0 auto", marginBottom:20 }}
                    />
                    ))}

                    <div className="line"></div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default PortfolioDetails;
