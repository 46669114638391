

const homeServices = [

    {
        id: 1,
        title: 'Sosyal Medya Yönetimi ve Post Tasarımı',
        desc: 'Sosyal medya stratejinizi güçlendirmek için etkileyici ve özgün içerikler oluşturuyor, sosyal medya hesaplarınızın yönetimini profesyonel bir şekilde gerçekleştiriyoruz.',
        class: ''
    },
    {
        id: 2,
        title: 'Kurumsal Kimlik Tasarımı',
        desc: 'Markanızın benzersiz kimliğini oluşturuyoruz. Logo, kartvizit ve diğer kurumsal materyallerle, markanızın tutarlı ve güçlü bir imajını yansıtıyoruz.',
        class: 'right'
    },

    {
        id: 3,
        title: 'Video Kurgu ve Hareketli Video Animasyonları',
        desc: 'Profesyonel video kurguları ve dinamik animasyon tasarımlarıyla, mesajınızı etkileyici bir şekilde iletmenizi sağlıyoruz. Videolarınızın tüm aşamalarında yaratıcı çözümler sunuyoruz.',
        class: ''
    },

    {
        id: 4,
        title: 'Afiş ve Poster Tasarımı',
        desc: 'Etkinlikleriniz ve kampanyalarınız için dikkat çekici afiş ve poster tasarımları hazırlıyoruz. Görsel olarak güçlü, etkili ve özgün tasarımlar sunuyoruz.',
        class: 'right'
    },
    {
        id: 5,
        title: 'Ambalaj Tasarımı',
        desc: 'Ürünlerinizin ambalajlarını estetik ve fonksiyonel bir şekilde tasarlayarak, markanızın değerlerini ve ürünlerinizi en iyi şekilde temsil ediyoruz.',
        class: ''
    }
]

export default homeServices;