

const homeWorkflow = [

    {
        id: 1,
        title: 'İhtiyaç Analizi ve Brief Toplama',
        desc: 'İlk aşamada, müşterilerimizle kapsamlı bir toplantı gerçekleştirerek proje ihtiyaçlarını ve hedeflerini belirliyoruz. Bu süreçte, tasarım beklentileri, proje kapsamı ve teslim tarihlerine dair detayları netleştiriyoruz.',
        class: ''
    },
    {
        id: 2,
        title: 'Tasarım Konsepti ve Onay',
        desc: 'Toplanan bilgiler doğrultusunda, birkaç farklı tasarım konsepti geliştiriyoruz. Bu tasarımlar müşterimize sunularak, tercihleri doğrultusunda seçim yapmaları sağlanıyor. Müşteri onayına göre, en uygun tasarımı belirliyoruz.',
        class: 'right'
    },

    {
        id: 3,
        title: 'Revizyon ve Düzenleme',
        desc: 'Seçilen tasarım üzerinden, müşteri geri bildirimlerine göre gerekli revizyonları gerçekleştiriyoruz. Bu aşamada, tasarımın tüm unsurlarını titizlikle düzenleyerek mükemmeliyet sağlıyoruz.',
        class: ''
    },

    {
        id: 4,
        title: 'Son Onay ve Üretim',
        desc: 'Revizyonların tamamlanmasının ardından, nihai tasarımı müşterimizin onayına sunuyoruz. Onay alındıktan sonra, tasarımı yüksek çözünürlüklü formatlarda hazırlayarak üretim sürecine geçiyoruz.',
        class: 'right'
    },
    {
        id: 5,
        title: 'Teslimat ve Sonrası Destek',
        desc: 'Tasarım, belirlenen formatlarda ve kalite standartlarına uygun olarak teslim ediliyor. Teslimat sonrası süreçte, müşterilerimize gerekli destek ve danışmanlık hizmetlerini sunarak, proje ile ilgili her türlü sorunu çözmeye yönelik yardımlarda bulunuyoruz.',
        class: ''
    },



]

export default homeWorkflow;