import React, { useState, useCallback, useRef } from "react";
import { saveAs } from "file-saver";
import { Link } from "react-router-dom";

function Converter() {
    const [selectedFile, setSelectedFile] = useState(null);
    const [error, setError] = useState("");
    const [preview, setPreview] = useState(null);
    const [isDragActive, setIsDragActive] = useState(false);
    const [selectedFormats, setSelectedFormats] = useState([]);
    const [availableFormats, setAvailableFormats] = useState(["jpeg", "png", "webp", "bmp"]); // Başlangıçta tüm formatlar
    const fileInputRef = useRef();

    const formatMap = {
        "image/jpeg": ["png", "webp", "bmp"], // JPEG yüklenirse JPEG seçeneği çıkar
        "image/png": ["jpeg", "webp", "bmp"],
        "image/webp": ["jpeg", "png", "bmp"],
        "image/bmp": ["jpeg", "png", "webp"]
    };

    const handleFileChange = (file) => {
        if (file) {
            setSelectedFile(file);
            setPreview(URL.createObjectURL(file));
            setError("");

            // Dosya tipine göre uygun formatları belirle
            const newFormats = formatMap[file.type] || ["jpeg", "png", "webp", "bmp"];
            setAvailableFormats(newFormats); // Mevcut formatları güncelle
        } else {
            setError("Lütfen geçerli bir dosya seçin!");
        }
    };

    const onDrop = useCallback((event) => {
        event.preventDefault();
        setIsDragActive(false);
        const file = event.dataTransfer.files[0];
        handleFileChange(file);
    }, []);

    const onDragOver = (event) => {
        event.preventDefault();
        setIsDragActive(true);
    };

    const onDragLeave = () => {
        setIsDragActive(false);
    };

    const openFilePicker = () => {
        fileInputRef.current.click();
    };

    const handleFormatSelection = (format) => {
        setSelectedFormats((prev) =>
            prev.includes(format)
                ? prev.filter((f) => f !== format)
                : [...prev, format]
        );
    };

    const convertImage = async () => {
        if (!selectedFile) {
            setError("Lütfen bir resim seçin!");
            return;
        }

        if (selectedFormats.length === 0) {
            setError("Lütfen en az bir format seçin!");
            return;
        }

        try {
            const image = await loadImage(selectedFile);

            selectedFormats.forEach(async (format) => {
                const convertedBlob = await convertImageFormat(image, format);
                saveAs(convertedBlob, `converted-image.${format}`);
            });

            setError("");
        } catch (error) {
            console.error("Dönüştürme hatası:", error);
            setError("Resim dönüştürme sırasında bir hata oluştu.");
        }
    };

    const loadImage = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                const img = new Image();
                img.onload = () => resolve(img);
                img.onerror = reject;
                img.src = event.target.result;
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    };

    const convertImageFormat = (image, format) => {
        return new Promise((resolve) => {
            const canvas = document.createElement("canvas");
            canvas.width = image.width;
            canvas.height = image.height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(image, 0, 0);

            canvas.toBlob(
                (blob) => {
                    resolve(blob);
                },
                `image/${format}`,
                1
            );
        });
    };

    return (
        <>
            <div className="wrapper">
                <section className="touch">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="block-text center">
                                    <h3 className="heading">Image Converter ❤️</h3>
                                    <h6 className="sub-heading">
                                        <span>Convert Image to Multiple Formats</span>
                                    </h6>
                                </div>

                                <div className="touch__main">
                                    <form style={{ width: "100%" }} action="#" className="form-box">
                                        <div className="row">
                                            <div className="col">
                                                <div
                                                    className={`drop-zone ${isDragActive ? "active" : ""}`}
                                                    onClick={openFilePicker}
                                                    onDrop={onDrop}
                                                    onDragOver={onDragOver}
                                                    onDragLeave={onDragLeave}
                                                >
                                                    <p>Resmi buraya sürükleyip bırakın veya tıklayın</p>
                                                    <input
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={(e) => handleFileChange(e.target.files[0])}
                                                        style={{ display: "none" }}
                                                        ref={fileInputRef}
                                                    />
                                                    {preview && (
                                                        <div className="preview">
                                                            <img src={preview} alt="Preview" />
                                                        </div>
                                                    )}
                                                </div>

                                                {selectedFile && (
                                                    <div className="file-info mt-3">
                                                        <p><strong>Dosya Adı:</strong> {selectedFile.name}</p>
                                                        <p><strong>Boyut:</strong> {(selectedFile.size / 1024).toFixed(2)} KB</p>
                                                        <p><strong>Tip:</strong> {selectedFile.type}</p>
                                                    </div>
                                                )}

                                                <div className="block-text center pt-2">
                                                    {error && (
                                                        <p style={{ color: "#fa3d4d", marginTop: "10px" }}>
                                                            {error}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col">
                                                <label>Çıktı Formatları:</label>
                                                <div className="format-options">
                                                    {availableFormats.map((format) => (
                                                        <label key={format} style={{ marginRight: "10px" }}>
                                                            <input
                                                                type="checkbox"
                                                                value={format}
                                                                onChange={() => handleFormatSelection(format)}
                                                            />{" "}
                                                            {format.toUpperCase()}
                                                        </label>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mb-0">
                                            <div className="col">
                                                <Link to="" onClick={convertImage} className="action-btn">
                                                    <span className="fero">İndir</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default Converter;
